import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Led } from 'react-digit-led1'
import useInterval from '../useInterval'


export const Countdown = (props) => {
    dayjs.extend(duration)
    const [now, setNow] = useState(dayjs())
    const [delay, setDelay] = useState(500)


    useEffect(() => {
        if (props?.status?.toLowerCase() === 'success' || props?.status?.toLowerCase() === 'none') {
            setDelay(undefined)
        } else {
            setDelay(500)
        }
    }, [props.status])

    useInterval( () => {
        setNow(dayjs())
    }, delay)

    let timeLeft = "60:00"
    if (!!props.endTime) {
        const target = dayjs(props.endTime)
        
        const timeDiff = !!props.finishTime ? dayjs.duration(target.diff(props.finishTime)) : dayjs.duration(target.diff(now))
        timeLeft = Math.floor(timeDiff.asMinutes()) + ':' + timeDiff.format('ss')
    }

    const style = {
        fontSize: '25vmin',
        fontWeight: 'bold',
        color: '#f11',
    }


    return <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', backgroundColor: '#000'}}><Led shadow={true} shadowColor='#400' style={style}>{timeLeft}</Led></div>
}

export default Countdown