import React from 'react'
import Panda1 from "./panda1/Panda1";
import Panda2 from "./panda2/Panda2";
import Panda3 from "./panda3/Panda3";
import Panda4 from "./panda4/Panda4";
import DigitInput from "./DigitInput";
import useDigitInput from "react-digit-input";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";

export const PandaJager = ({freedPandas}) => {
    
    const [value, setValue] = React.useState('')

    const onChange = (value) => {
        setValue(value.toUpperCase())
    }

    const digits = useDigitInput({
        acceptedCharacters: /^[a-zA-Z]$/,
        length: 8,
        value,
        onChange,
    })
    
    const disabled = value.length === 0
    
    const queryClient = useQueryClient()

    //const {isLoading, error, data} = useQuery([], () => axios.post('/api/game').then(res => res.json()))
    const pandaJagerMutation = useMutation((code) => axios.post('/api/game/pandajager', {code: code}),
                                      {
                                          onSuccess: async (gameInfo) => {
                                              queryClient.invalidateQueries(['game'])
                                          }
                                      }
                                      )
    
    
    const pandaJager = (e) => {
        e.preventDefault()
        pandaJagerMutation.mutate(value)
    }
    
    return (
        <>
        <div className='digits'>
            <div style={{ display: "flex", justifyContent: "space-evenly", margin: "5px", width: "100%"}}>
            <Panda1 isFreed={freedPandas?.includes(0)}/>
            <Panda2 isFreed={freedPandas?.includes(1)}/>
            <Panda3 isFreed={freedPandas?.includes(2)}/>
            <Panda4 isFreed={freedPandas?.includes(3)}/>
        </div>
            <span>Waar kan je de panda jager vinden?</span>
            <div className='input-group'>
            <DigitInput mode="text" {...digits[0]} />
            <DigitInput mode="text" {...digits[1]} />
            <DigitInput mode="text" {...digits[2]} />
            <DigitInput mode="text" {...digits[3]} />
            <DigitInput mode="text" {...digits[4]} />
                <DigitInput mode="text" {...digits[5]} />
                <DigitInput mode="text" {...digits[6]} />
                <DigitInput mode="text" {...digits[7]} />
        </div>
            <button disabled={disabled} onClick={pandaJager} >Zend code</button>
            </div>
        </>
        )
}

export default PandaJager