import React from 'react'
import './panda1.css'


export const Panda1 = ({isFreed = false}) => {
    return (
        <div className={isFreed ? "panda1-Freed" : "panda1-Caged"} style={{ width: "30vmin", height: "20vmin"}}/>
    )
}

export default Panda1