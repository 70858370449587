import React from 'react'

export const Failed = () => {
    return(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', backgroundColor: '#000', fontSize: '10vmin', color: '#f55'}}>
            Oei oei oei!<br /> De panda jager is ontsnapt!
        </div>
    )
}

export default Failed