import React from 'react'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import './Fase1.css'

export const StartGame = (props) => {
    const queryClient = useQueryClient()

    //const {isLoading, error, data} = useQuery([], () => axios.post('/api/game').then(res => res.json()))
    const startGameMutation = useMutation(() => axios.post('/api/game'),
        {
            onSuccess: async (gameInfo) => {
                queryClient.invalidateQueries(['game'])
            }
        }
    )

    const startGame = (e) => {
        e.preventDefault()
        startGameMutation.mutate()
    }

    return (<>
        <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', backgroundColor: '#000'}} >
            <button onClick={startGame}>Start</button>
        </div>
    <div className='digits'>
        <h1>Welkom tot de Escape Room: Pandajager!</h1>
    </div>
    </>
    )
}
export default StartGame