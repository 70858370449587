import React from 'react'

export const DigitInput = React.forwardRef(({...props}, ref) => {
    return (
        <label>
            <div className='digit'>{props.value}</div>
            <hr />
            <input inputMode={!!props?.mode ? props.mode : "decimal"} ref={ref} {...props} />
        </label>
    )
})

export default DigitInput