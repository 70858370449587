import React from 'react'
import './panda2.css'


export const Panda2 = ({isFreed = false}) => {
    return (
        <div className={isFreed ? "panda2-Freed" : "panda2-Caged"} style={{ width: "30vmin", height: "20vmin"}}/>
    )
}

export default Panda2