import React from 'react'
import './panda3.css'


export const Panda3 = ({isFreed = false}) => {
    return (
        <div className={isFreed ? "panda3-Freed" : "panda3-Caged"} style={{ width: "30vmin", height: "20vmin"}}/>
    )
}

export default Panda3