import React from 'react'

export const Success = () => {
    return(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', backgroundColor: '#000', fontSize: '10vmin', color: '#f55'}}>
            Gefeliciteerd!<br /> Jullie hebben de panda jager gevat!
        </div>
    )
}

export default Success