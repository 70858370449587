import React from 'react'
import './panda4.css'


export const Panda4 = ({isFreed = false}) => {
    return (
        <div className={isFreed ? "panda4-Freed" : "panda4-Caged"} style={{ width: "30vmin", height: "20vmin"}}/>
    )
}

export default Panda4