import React from 'react'
import Panda1 from "./panda1/Panda1";
import Panda2 from "./panda2/Panda2";
import Panda3 from "./panda3/Panda3";
import Panda4 from "./panda4/Panda4";
import useDigitInput from "react-digit-input";
import DigitInput from "./DigitInput";
import './Fase1.css'
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";

export const Panda = ({freedPandas}) => {
    const [value, setValue] = React.useState('')
    
    const onChange = (value) => {
        setValue(value.toUpperCase())
    }
    
    const digits = useDigitInput({
        acceptedCharacters: /^[a-zA-Z]$/,
        length: 5,
        value,
        onChange,
    })
    
    const queryClient = useQueryClient()
    
    //const {isLoading, error, data} = useQuery([], () => axios.post('/api/game').then(res => res.json()))
    const pandaMutation = useMutation((code) => axios.post('/api/game/panda', {code: code}),
                                      {
                                          onSuccess: async (gameInfo) => {
                                              queryClient.invalidateQueries(['game'])
                                              setValue('')
                                          },
                                      }
                                      )

    const panda = (e) => {
        e.preventDefault()
        pandaMutation.mutate(value)
    }
    
    const disabled = digits.filter(d => !d.value).length !== 0
    
    return (
        <>
        <div className='digits'>
            <span>Bevrijdt de panda's!</span>
            <div style={{ display: "flex", justifyContent: "space-evenly", margin: "5px", width: "100%" }}>
            <Panda1 isFreed={freedPandas.includes(0)}/>
            <Panda2 isFreed={freedPandas.includes(1)}/>
            <Panda3 isFreed={freedPandas.includes(2)}/>
            <Panda4 isFreed={freedPandas.includes(3)}/>
        </div>
        
        <div className='input-group'>
            <DigitInput mode="text" {...digits[0]} />
            <DigitInput mode="text" {...digits[1]} />
            <DigitInput mode="text" {...digits[2]} />
            <DigitInput mode="text" {...digits[3]} />
            <DigitInput mode="text" {...digits[4]} />
        </div>
            <button disabled={disabled} onClick={panda} >Zend code</button>
            </div>
        </>
        )
}

export default Panda