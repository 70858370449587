import React from 'react'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

import Countdown from './Countdown/Countdown'
import StartGame from './UserArea/StartGame'
import PandaJager from './UserArea/PandaJager'
import { Success } from './UserArea/Success'
import { Failed } from './UserArea/Failed'
import Panda from "./UserArea/Panda";

export const App = (props) => {

    const { status, data} = useQuery(['game'], async () => {
        const res = await axios.get('/api/game')
        return res.data
    })

    const endTime = status === 'success' ? data.endTime : undefined
    const gameStatus = data?.status

    let userArea
    switch (gameStatus) {
        case 'PANDA':
            userArea = <Panda freedPandas={data.freedPandas}/>
            break
        case 'PANDAJAGER':
            userArea = <PandaJager freedPandas={data.freedPandas}/>
            break
        case 'SUCCESS':
            userArea = <Success />
            break
        case 'FAILED':
            userArea = <Failed />
            break
        default:
            userArea = <StartGame/>
    }

    return (<div>
        <Countdown endTime={endTime} finishTime={data?.finishTime} status={gameStatus}/>
        {userArea}
    </div>)
}

export default App